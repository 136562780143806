<template>
  <div class="index">
    <div class="flex justify_content_space_between width_full info">
      <div class="info-top">
        <div class="info_title bold">
          <img v-if="info.isTop == 1" src="@/assets/img/news/zd.png" class="zd-img">
          <span v-if="info.isTop == 1"  ref="title">{{(info.title)|ellipsis}}</span>
          <span v-else  ref="title">{{(info.title)|ellipsisTop}}</span>
        </div>
        <div class="flex justify_content_space_between into_text">
         <div>
            <span v-if="info.infoClass" class="classify" :style="{'color' : info.infoClass.classTitleColor,  'background': info.infoClass.backgroundColor }">{{info.infoClass.classTitle}}</span>
         </div>
          <div>
            <span>{{info.author}}</span>
            <span>{{info.publishDate}}</span>
          </div>
        </div>
      </div>
      <div>
        <div class="flex align_items_center height_full">
          <van-image :src="JSON.parse(this.info.imagesUrl)[0].replace('fileTransferPath',newsImgBaseUrl)"
                     class="info_image"
                     radius="5">
          </van-image>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { newsImgBaseUrl } from "../../../utils/api";
export default {
  name: "ruralInformationPicOne",
  props: ['info'],
  data () {
    return {
      newsImgBaseUrl,
      titleWidth: 0
    }
  },
  filters: {
    ellipsisTop (value) {
      if (!value) return ''
      if (value.length > 31) {
        return value.slice(0, 31) + '...'
      }
      
      return value
    },
    ellipsis (value) {
      if (!value) return ''
      if (value.length > 28) {
        return value.slice(0, 28) + '...'
      }
      
      return value
    },
  },
  methods: {

  },
  mounted () {
    var w = document.documentElement.clientWidth || document.body.clientWidth;
    this.titleWidth = (w - (w / 7.5 * 0.2) - 100) + 'px'
  }
}
</script>

<style lang="less" scoped>
.index {
  background-color: white;
  .icon-img {
    height: 0.36rem;
  }
  .flex {
    display: flex;
  }
  .line {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    height: 1px;
    margin-top: 18px;
    background-color: #f7f8f9;
  }
  .classify{
    padding: 0.05rem 0.08rem 0.04rem;
    border-radius: 0.05rem;
    font-size: 0.26rem;
        height: 0.35rem;
    line-height: 0.35rem;
  }
  .zd-img{
    width: 26px;
    height: 15px;
    float: left;
    margin-right:0.1rem;
    margin-top: 0.07rem;
  }
  .info {
    padding: 16px 0px 0px 5px;
    align-items: flex-start;
    .info-top {
      width: calc(100% - 120px);
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .top-flag{
      position: absolute;
    }
    .info_title {
      padding-top: 0px;
      font-size: 15px;
      margin-left: 0.2rem;
      word-wrap: break-word;
      word-break: break-all;
      div{
        clear: both;
      }
    }
    .into_text {
      font-size: 13px;
      color: #999999;
      margin-left: 0.2rem;
      margin-right: 0;
      margin-top: 10px;
      align-items: center;
      span:nth-child(1) {
        margin-right: 0.1rem;
      }
    }
    .info_image {
      margin-right: 0.2rem;
      width: 100px;
      height: 80px;
    }
  }
}
</style>
