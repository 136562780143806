<template>
  <div class="index">
    <div class="news_title bold">
      <img v-if="info.isTop == 1" src="@/assets/img/news/zd.png" class="zd-img">
      <span v-if="info.isTop == 1"  ref="title">{{(info.title)|ellipsis}}</span>
      <span v-else ref="title">{{(info.title)|ellipsisTop}}</span>
    </div>
    <div class="flex justify_content_space_between news_image">
      <van-image v-for="(item,index) in JSON.parse(this.info.imagesUrl) "
                 :key="index"
                 :src="item.replace('fileTransferPath',newsImgBaseUrl)"
                 :width='imageWidth'
                 :height="imageHeight"
                 radius="5px">
      </van-image>
    </div>
    <div class="flex justify_content_space_between news_text">
      <div>
        <span v-if="info.infoClass"
              class="classify"
              :style="{'color' : info.infoClass.classTitleColor,  'background': info.infoClass.backgroundColor }">{{info.infoClass.classTitle}}</span>
      </div>
      <div>
        <span>{{info.author}}</span>
        <span>{{info.publishDate}}</span>
      </div>
    </div>
    <div class="line">

    </div>
  </div>
</template>

<script>
import { newsImgBaseUrl } from "../../../utils/api";

export default {
  name: "ruralInformationPicThree",
  props: ['info'],
  data () {
    return {
      newsImgBaseUrl,
      imageWidth: 0,
      imageHeight: 0
    }
  },
  filters: {
    ellipsisTop (value) {
      if (!value) return ''
      if (value.length > 22) {
        return value.slice(0, 22) + '...'
      }
      
      return value
    },
    ellipsis (value) {
      if (!value) return ''
      if (value.length > 20) {
        return value.slice(0, 20) + '...'
      }
      return value
    }
  },
  mounted () {
    var w = document.documentElement.clientWidth || document.body.clientWidth;
    this.imageWidth = (w - (w / 7.5 * 0.4) - 20) / 3 + 'px'
    this.imageHeight = (w - (w / 7.5 * 0.4) - 20) / 3 / 100 * 80 + 'px'
  }
}
</script>

<style lang="less" scoped>
.index {
  background-color: white;
  .icon-img {
    height: 0.36rem;
  }
  .top-flag {
    position: absolute;
  }
  .news_title {
    padding-top: 10px;
    margin-left: 0.3rem;
    margin-right: 0.2rem;
    font-size: 15px;
    word-wrap: break-word;
    word-break: break-all;
    span{
      clear:both;
    }
  }
  .classify {
    padding: 0.05rem 0.08rem 0.04rem;
    border-radius: 0.05rem;
    font-size: 0.26rem;
    height: 0.35rem;
    line-height: 0.35rem;
  }
  .zd-img{
    width: 26px;
    height: 15px;
    float: left;
    margin-right:0.1rem;
    margin-top: 0.07rem;
  }
  .news_image {
    margin-top: 10px;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
  .news_text {
    margin-top: 10px;
    font-size: 13px;
    color: #999999;
    margin-left: 0.3rem;
    margin-right: 0.2rem;
        align-items: center;
    span:nth-child(1) {
      margin-right: 0.1rem;
    }
  }
  .line {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    height: 0.5px;
    background-color: #eeeeee;
    margin-top: 14px;
  }
}
</style>
